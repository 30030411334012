@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 100;
  src: url("./Fonts/Woff/IRANSansXFaNum-Thin.woff"),
    url("./Fonts/Woff2/IRANSansXFaNum-Thin.woff2");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  src: url("./Fonts/Woff/IRANSansXFaNum-UltraLight.woff"),
    url("./Fonts/Woff2/IRANSansXFaNum-UltraLight.woff2");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  src: url("./Fonts/Woff/IRANSansXFaNum-Light.woff"),
    url("./Fonts/Woff2/IRANSansXFaNum-Light.woff2");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url("./Fonts/Woff/IRANSansXFaNum-Regular.woff"),
    url("./Fonts/Woff2/IRANSansXFaNum-Regular.woff2");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  src: url("./Fonts/Woff/IRANSansXFaNum-Medium.woff"),
    url("./Fonts/Woff2/IRANSansXFaNum-Medium.woff2");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 600;
  src: url("./Fonts/Woff/IRANSansXFaNum-DemiBold.woff"),
    url("./Fonts/Woff2/IRANSansXFaNum-DemiBold.woff2");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  src: url("./Fonts/Woff/IRANSansXFaNum-Bold.woff"),
    url("./Fonts/Woff2/IRANSansXFaNum-Bold.woff2");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 800;
  src: url("./Fonts/Woff/IRANSansXFaNum-ExtraBold.woff"),
    url("./Fonts/Woff2/IRANSansXFaNum-ExtraBold.woff2");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 900;
  src: url("./Fonts/Woff/IRANSansXFaNum-Black.woff"),
    url("./Fonts/Woff2/IRANSansXFaNum-Black.woff2");
}
:root {
  --background: #FCFCFC;
  --background-1: #ffffff;
  --background-d: #121212;
  --background-d-art: #2F2F2F;
  --text-1: #1E1E1E;
  --text-3: #2F2F2F;
  --text-d-1: #fff;
  --text-2: #D9D9D9;

  --grey-1: #F9F9F9;
  --grey-2: #E5E5E5;


  /* light colors */
  --nav-bg: #ffffffaf !important;
  --bg-d-1: #eaeaea;
  --bg-d-2: #e0e0e0;
  --bg-d-3: #d0d0d0;
  --fsc-d-1: #121212;

  --n-grey-1: #E5E5E5;
  --n-grey-2: #979797;
  --n-grey-3: #5E5E5E;

  --fs-small: 10px;
  --fs-textfield: 13px;
  --fs-textfield-2: 16px;
  --trans-1: all 0.2s ease-in-out;



  --error-bg: #dff6dd;
  --error-border: #107c10;
  --error-bg-yellow: #fff4ce;
  --error-border-yellow: #797775;
  --error-bg-red: #fde7e9;
  --error-border-red: #a80000;

}
* {
  font-family: IRANSans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-seri;
  padding: 0;
  margin: 0;
}
input:focus {
  outline: none;
}
body {
  background: var(--bg-d-1);
  margin: 0 auto;
  font-family: IRANSans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: var(--fsc-d-1);
  direction: rtl;
  font-size: 14px;
  overflow-x: hidden;
}
input {
  font-family: IRANSans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-seri;
}
.MuiFormLabel-root {
  font-family: IRANSans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-seri !important;
}
.MuiButton-root {
  font-size: 1rem !important;
  font-family: IRANSans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-seri !important;
}

.spacer-l {
  width: 100%;
  height: 2rem;
}
.spacer-m {
  width: 100%;
  height: 1rem;
}
.spacer-s {
  width: 100%;
  height: 0.5rem;
}

.devider-m {
  width: 100%;
  height: 1px;
  background-color: var(--border-2);
}

.shimmer-effect {
  background-image: linear-gradient(
    to right,
    var(--border-1) 0%,
    var(--body-2) 20%,
    var(--border-1) 40%,
    var(--border-1) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 800px;
  animation: placeholderShimmer 1s ease-in-out infinite 0s backwards !important;
}

@keyframes placeholderShimmer {
  0% {
    background-position: 400px 0;
  }

  100% {
    background-position: -400px 0;
  }
}